<template>
  <div>
    <b-card-body class="p-2">

      <div class="mb-2">
        <b-button
          size="sm"
          :variant="rateId==='all'?'success':'outline-success'"
          @click="rateId='all'"
          class="mr-2">ทั้งหมด</b-button>
        <b-button
          v-for="rate in rates"
          size="sm"
          :variant="rateId===rate.rateId?'success':'outline-success'"
          @click="rateId=rate.rateId"
          :key="rate.rateId"
          class="mr-2">{{rate.rateTitle}}</b-button>
      </div>

      <table class="table table-bordered table-pp mb-0">
        <thead>
          <tr>
            <th rowspan="2">ประเภท</th>
            <th rowspan="2" width="10%" style="border-right: 2px solid #ffc107;">ยอดทั้งหมด</th>
            <th colspan="3" style="border-right: 2px solid #ffc107;">สมาชิก</th>
            <th colspan="3" style="border-right: 2px solid #ffc107;">{{agentName}}</th>
            <th colspan="3">บริษัท</th>
          </tr>
          <tr>
            <th width="8%" class="p-1">ส่งออก</th>
            <th width="8%" class="p-1">คอมฯ</th>
            <th width="8%" class="p-1" style="border-right: 2px solid #ffc107;">รวม</th>
            <th width="8%" class="p-1">ถือหุ้น</th>
            <th width="8%" class="p-1">คอมฯ</th>
            <th width="8%" class="p-1" style="border-right: 2px solid #ffc107;">รวม</th>
            <th width="8%" class="p-1">ถือหุ้น</th>
            <th width="8%" class="p-1">คอมฯ</th>
            <th width="8%" class="p-1">รวม</th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="11" class="text-center alert-pp">กรุณารอซักครู่...</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading">
          <tr v-for="item in items" :key="item.type">
            <td class="text-center">{{item.text}}</td>
            <td class="text-right px-1 text-success" style="border-right: 2px solid #ffc107;">{{item.summary.amount | amountFormat(2, '0.00')}}</td>
            <td class="text-right px-1 text-danger">{{item.summary.memberAmount | amountFormat(2, '0.00')}}</td>
            <td class="text-right px-1 text-success">{{item.summary.memberCommission | amountFormat(2, '0.00')}}</td>
            <td class="text-right px-1 text-danger" style="border-right: 2px solid #ffc107;">{{item.summary.memberTotal | amountFormat(2, '0.00')}}</td>

            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.agentAmount>0
              }, {
                'text-danger': item.summary.agentAmount<0
              }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.agentCommission>0
              }, {
                'text-danger': item.summary.agentCommission<0
              }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1" style="border-right: 2px solid #ffc107;"
              :class="[{
                'text-success': item.summary.agentTotal>0
              }, {
                'text-danger': item.summary.agentTotal<0
              }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.companyAmount>0
              }, {
                'text-danger': item.summary.companyAmount<0
              }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.companyCommission>0
              }, {
                'text-danger': item.summary.companyCommission<0
              }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.companyTotal>0
              }, {
                'text-danger': item.summary.companyTotal<0
              }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading && !items.length">
          <tr>
            <td colspan="11" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="!isLoading && items.length">
          <tr>
            <th class="text-center">รวม</th>
            <td class="text-right px-1 text-success" style="border-right: 2px solid #ffc107;">{{ summary.amount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 text-danger">{{ summary.memberAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 text-success">{{ summary.memberCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1 text-danger" style="border-right: 2px solid #ffc107;">{{ summary.memberTotal | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentAmount>0
              }, {
                'text-danger': summary.agentAmount<0
              }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentCommission>0
              }, {
                'text-danger': summary.agentCommission<0
              }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1" style="border-right: 2px solid #ffc107;"
              :class="[{
                'text-success': summary.agentTotal>0
              }, {
                'text-danger': summary.agentTotal<0
              }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyAmount>0
              }, {
                'text-danger': summary.companyAmount<0
              }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyCommission>0
              }, {
                'text-danger': summary.companyCommission<0
              }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyTotal>0
              }, {
                'text-danger': summary.companyTotal<0
              }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</td>
          </tr>
        </tfoot>
      </table>
    </b-card-body>
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundReportByType',
  props: ['accountId', 'memberAccountId'],
  data() {
    return {
      data: null,
      isLoading: false,
      rateId: 'all'
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    changed() {
      return `${this.roundId}-${this.accountId}-${this.memberAccountId}`
    },
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    items() {
      if(!this.data)
        return []

      const reports = this.data.reports.filter((report)=>{
        return report._id.rateId === this.rateId || this.rateId === 'all'
      })

      const groups = _.groupBy(reports, (report)=>{
        return report.type
      })

      const items = []
      for(const type in groups) {
        const summary = groups[type].reduce((total, item)=>{
          total.amount += item.amount
          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberTotal += item.memberTotal
          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentTotal += item.agentTotal
          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyTotal += item.companyTotal
          return total
        }, {
          amount: 0,
          memberAmount: 0,
          memberCommission: 0,
          memberTotal: 0,
          agentAmount: 0,
          agentCommission: 0,
          agentTotal: 0,
          companyAmount: 0,
          companyCommission: 0,
          companyTotal: 0
        })

        items.push({
          text: groups[type][0].text,
          type: type,
          summary: summary
        })
      }

      return _.sortBy(items, [(item)=>{
        return {
          threeNumberTop: 1,
          threeNumberTode: 2,
          twoNumberTop: 3,
          twoNumberBottom: 4,
          runTop: 5,
          runBottom: 6
        }[item.type]
      }])
    },
    summary() {
      return this.items.reduce((total, item)=>{
        total.amount += item.summary.amount
        total.memberAmount += item.summary.memberAmount
        total.memberCommission += item.summary.memberCommission
        total.memberTotal += item.summary.memberTotal
        total.agentAmount += item.summary.agentAmount
        total.agentCommission += item.summary.agentCommission
        total.agentTotal += item.summary.agentTotal
        total.companyAmount += item.summary.companyAmount
        total.companyCommission += item.summary.companyCommission
        total.companyTotal += item.summary.companyTotal
        return total
      }, {
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        memberTotal: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentTotal: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyTotal: 0
      })
    },
    rates() {
      if(!this.data)
        return []

      return this.data.rates
    }
  },
  watch: {
    changed() {
      this.getReport()
    }
  },
  methods: {
    getReport() {
      this.isLoading = true

      if(!this.roundId)
        return

      const accountId = this.accountId ? this.accountId : this.$store.state.account._id
      ReportService.getRoundReportByType('wait', this.roundId, accountId, this.memberAccountId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('data', response.data)
          this.$emit('Breadcrumbs', response.data.agent.parents)
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getReport()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        padding: 5px;
        text-align: center;
        font-weight: 500;
        font-size: 95%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 90%;
      }
    }
  }
  tfoot {
    tr {
      td, th {
        font-size: 90%;
        padding: 5px;
      }
    }
  }
}
</style>
